import "./vendors/select2.js";

let isMobile;
const checkMobile = () => {
    isMobile = isMobileWindow();
};
const isMobileWindow = () => {
    const mq = window.matchMedia("(max-width: 992px)");
    return mq.matches;
};

const hamburger = () => {
    
    const btn = $(".burger-js");
    const menu = $(".main-nav-js");

    if (isMobile) {
        var open = false;
        if ($("body").hasClass("menu-opened")) {
            open = true;
        }
        btn.on("click", function () {
            if (open === false) {
                open = true;
                $("body").addClass("menu-opened");
                menu.addClass("show-menu");
                btn.addClass("active");
            } else {
                open = false;
                $("body").removeClass("menu-opened");
                menu.removeClass("show-menu");
                btn.removeClass("active");
            }
        });
    }
};


const langSwitcher = () => {
    const box = $('.lang-switch');
    const btn = $('.lang-switch-js');
    const menu = $('.lang-switch__list');

    function active() {
        box.addClass('active')
        btn.addClass('active');
        menu.addClass('active');
    }

    function activeRemove() {
        box.removeClass('active')
        btn.removeClass('active');
        menu.removeClass('active');
    }

    if (isMobile) {
        var open = false;

        if(box.hasClass('active')) {
            open = true;
        }

        btn.on("click", function () {
            if (open === false) {
                open = true;
                active();
            } else {
                open = false;
                activeRemove();
            }
        });

    } else {
        box.on('mouseover', function() { 
            active();
        });

        box.on('mouseout', function() { 
            activeRemove();
        });
    }

}       

const selectCustom = () => {
    if($('.gfield_select').length > 0) {
        $('.gfield_select').select2({
            minimumResultsForSearch: -1,
            width: 'resolve',
        });

        $('.gfield_select').on('select2:select', function (e) {
            $(this).siblings().addClass('changed');
        });
    }
};

const dateCustom = () => {
    if($('.js-date').length > 0) {
        gform.addFilter( 'gform_datepicker_options_pre_init', function( optionsObj, formId, fieldId ) {
            optionsObj.minDate = 0;
            optionsObj.firstDay = 1;
            optionsObj.dayNamesMin = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
            optionsObj.changeMonth = false;
            optionsObj.changeYear = false;
            optionsObj.showOtherMonths = false;
            optionsObj.nextText = '';
            optionsObj.prevText = '';

            return optionsObj;
        });
    }
};

const visaModal = () => {
    if($('.js-modal-open').length > 0) {
        $( ".js-modal-open" ).on( "click", function(event) {
            event.preventDefault();
            $('.form-modal').addClass('active');
        });

        $( ".js-modal-close" ).on( "click", function() {
            $('.form-modal').removeClass('active');
        });
    }
};

const inputFilled = () => {
    if($('input').length > 0) {
        function checkForInput(element) {
            if ($(element).val().length > 0) {
                $(element).addClass('input-has-value');
            } else {
                $(element).removeClass('input-has-value');
            }
        }

        $('input').each(function() {
            checkForInput(this);
        });

        $('input').on('change keyup', function() {
            checkForInput(this);  
        });
    }
};

const loadMore = () => {

    $('.js-loadmore').on('click', function(){
 
		var button = $(this),
		    data = {
			'action': 'loadmore',
			'query': loadmore_params.posts, 
			'page' : loadmore_params.current_page
		};
 
		$.ajax({
			url : loadmore_params.ajaxurl,
			data : data,
			type : 'POST',
			success : function( data ){
				if( data ) { 
                    $('.article-row').append(data);
					loadmore_params.current_page++;
 
					if ( loadmore_params.current_page == loadmore_params.max_page ) 
						button.remove();

				} else {
					button.remove();
				}
			}
		});
	});
};

const scrollNext = () => {
    if($('.js-next-section').length > 0) {
        $(".js-next-section").on('click', function(e) {
            e.preventDefault();
            var aid = $(this).attr("href");
            $('html,body').animate({scrollTop: $(aid).offset().top},'slow');
        });
    }
};

export {
    checkMobile,
    isMobileWindow,
    hamburger,
    langSwitcher,
    selectCustom,
    dateCustom,
    visaModal,
    inputFilled,
    loadMore,
    scrollNext
};
