import "./vendors/jquery-3.6.0.min.js";

import {
    checkMobile,
    hamburger,
    langSwitcher,
    loadMore,
    selectCustom,
    dateCustom,
    visaModal,
    inputFilled,
    scrollNext
} from "./site";

$(document).ready(() => {
    checkMobile();
    hamburger();
    langSwitcher();
    selectCustom();
    dateCustom();
    visaModal();
    inputFilled();
    loadMore();
    scrollNext();
});

$(window).on("resize", () => {
    checkMobile();
    hamburger();
    langSwitcher();
});